<template>
  <SmoothReflow v-show="model.$anyError">
    <ul class="text-danger">
      <li
        v-for="error in filteredErrors"
        :key="`${data.name}__error--${error.args.field}__${error.name}`"
        v-t="error" />
    </ul>
  </SmoothReflow>
</template>

<script>
import { getValidationErrors } from '@/services/validation/getValidationErrors';
import { snakeCase } from 'lodash-es/string';

export default {
  name: 'FieldErrors',
  props: {
    data: {
      type: Object,
      default: null,
    },
    model: {
      type: Object,
      default: null,
      required: true,
    },
  },

  computed: {
    /**
     * Filter the list of errors to only keep the errors that apply to the current model. If $model is an object it
     * means we're dealing with a group of validations.
     *
     * @returns {Object[]}
     */
    filteredErrors() {
      if (this.model.$model && typeof this.model.$model === 'object') {
        return Object
          .keys(this.model.$model)
          .reduce((acc, modelName) => {
            const childData = this.data.children.find(({ name }) => name === modelName);
            const childModel = this.model[modelName];
            const childValidationErrors = getValidationErrors(childModel);

            return [
              ...acc,
              ...this.createErrorMap(childValidationErrors, childModel, childData),
            ];
          }, []);
      }

      const validationErrors = getValidationErrors(this.model);
      return this.createErrorMap(validationErrors);
    },
  },

  methods: {
    /**
     * Map all errors to an array of objects to easily use in the template.
     *
     * @param {Object[]} errors
     * @param {Object} model
     * @param {Object} data
     *
     * @returns {Object[]}
     */
    createErrorMap(errors, model = this.model, data = this.data) {
      return errors.reduce((acc, error) => {
        const isValid = model[error.name];
        const hasThisError = model.$params?.hasOwnProperty(error.name);

        if (!model.$dirty || model.$pending || !hasThisError || isValid) {
          return acc;
        }

        const translationPath = `form.error_${snakeCase(error.name)}`;

        return [
          ...acc,
          {
            args: {
              field: data.label ? this.$t(data.label) : null,
              ...error.args,
            },
            name: error.name,
            path: error.path ?? translationPath,
          },
        ];
      }, []);
    },
  },
};
</script>
