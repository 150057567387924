import { maxLength, required } from 'vuelidate/lib/validators';
import { AbstractField } from '@/data/forms/fields/abstractField';
import { POSTAL_CODE } from '@/services/validation/validations';
import { createPostalCodeValidator } from '@/services/validation/createPostalCodeValidator';

export class PostalCodeField extends AbstractField {
  getField() {
    return {
      name: 'postal_code',
      label: 'form.address_postal_code',
      inputAttributes: {
        autocomplete: 'postal-code',
      },
      validations: {
        required,
        maxLength: maxLength(10),
        [POSTAL_CODE]: createPostalCodeValidator('cc'),
      },
    };
  }
}
