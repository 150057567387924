import { get } from 'lodash-es/object';

/**
 * Returns a validator function based on value of given path.
 *
 * @param {String} path
 * @param {Function} callback
 * @param {Boolean} defaultValue
 *
 * @returns {Function<Boolean>}
 */
export function createConditionalValidator(path, callback, defaultValue = false) {
  return (value, vm) => {
    if (!value) {
      return defaultValue;
    }

    let conditionValue = null;

    if (path) {
      conditionValue = get(vm, path, null);
    }

    return callback(value, conditionValue);
  };
}
