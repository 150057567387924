const JSON_INDENT = 2;

/**
 * @param {Object[]} form
 *
 * @returns {Object<'form', Object>}
 */
export function createValidations(form) {
  const reducer = (form) => form.reduce((acc, val) => {
    if (val.type === 'submit') {
      return acc;
    }

    if (val.hasOwnProperty('children')) {
      return {
        ...acc,
        [val.name]: reducer(val.children),
      };
    }

    return {
      ...acc,
      [val.name]: val.validations || {},
    };
  }, {});

  return {
    formModel: reducer(form, {}),
  };
}
