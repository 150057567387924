<template>
  <component
    :is="component"
    v-if="component"
    v-model="model.$model"
    v-bind="inputAttributesToBind" />

  <CButton
    v-else-if="'submit' === type"
    v-test="{ id: 'field', type }"
    :size="size"
    @click="$emit('submit')">
    {{ translatedLabel }}
  </CButton>

  <div v-else>
    <div class="form-floating form-group">
      <CountrySelect
        v-if="type === 'countrySelect'"
        v-model="model.$model"
        v-skeleton="skeleton"
        v-test="{ id: 'field', type }"
        v-bind="inputAttributesToBind" />

      <select
        v-else-if="type === 'select'"
        v-model="model.$model"
        v-skeleton="skeleton"
        v-test="{ id: 'field', type }"
        v-bind="inputAttributesToBind">
        <option
          v-for="(option, index) in options"
          :key="index"
          v-test="`option_${index}`"
          :value="option.value"
          v-text="option.text" />
      </select>

      <input
        v-else
        v-model.trim.lazy="model.$model"
        v-skeleton="skeleton"
        v-test="{
          type,
          id: 'field',
          name: inputName,
          e2e: inputName,
        }"
        v-bind="inputAttributesToBind">

      <label
        v-if="!hideLabel && !skeleton"
        :for="inputName"
        class="field__label whitespace-nowrap">
        {{ translatedLabel }}
        <span
          v-if="model.$params && model.$params.required"
          class="required-indicator"
          v-text="'*'" />
      </label>
    </div>

    <FieldErrors
      v-if="!skeleton && !hideErrors"
      v-show="model.$anyError"
      :data="$props"
      :name="name"
      :model="model" />
  </div>
</template>

<script>
import CButton from '@/components/CButton';
import CountrySelect from '@/components/CountrySelect';
import FieldErrors from '@/components/forms/FieldErrors';
import Vue from 'vue';
import { hasSizeProp } from '@/mixins/hasSizeProp';
import { snakeCase } from 'lodash-es';

const SELECTS = ['select', 'countrySelect'];

export default {
  name: 'Field',
  components: {
    CButton,
    CountrySelect,
    FieldErrors,
  },

  mixins: [hasSizeProp],
  props: {
    /**
     * Use this prop to render the field as a skeleton loader.
     */
    skeleton: {
      type: Boolean,
    },

    /**
     * Used to show or hide labels.
     */
    hideLabel: {
      type: Boolean,
    },

    /**
     * Used to show or hide validation errors.
     */
    hideErrors: {
      type: Boolean,
    },

    /**
     * Form element identifier.
     */
    name: {
      type: String,
      default: null,
    },

    /**
     * Input type.
     */
    type: {
      type: String,
      default: 'text',
    },

    /**
     * Label text. Required even when hiding labels for accessibility.
     */
    label: {
      type: String,
      default: null,
    },

    /**
     * Attributes to bind to the input element.
     */
    inputAttributes: {
      type: Object,
      default: null,
    },

    /**
     * Attributes to bind to the input wrapper.
     */
    columnAttributes: {
      type: Object,
      default: null,
    },

    /**
     * Custom component to render. Must be v-model compatible to be able to function as a form input.
     */
    component: {
      type: [Object, Vue],
      default: null,
    },

    /**
     * The field's model.
     */
    model: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    /**
     * A collection of attributes to bind to each variation of the field.
     *
     * @returns {Object}
     */
    inputAttributesToBind() {
      return {
        autocomplete: 'off',
        class: this.fieldClass,
        name: this.inputName,
        placeholder: this.translatedLabel,
        type: this.type,
        ...this.inputAttributes,
      };
    },

    /**
     * Creates a class string for the current field.
     *
     * @returns {string}
     */
    fieldClass() {
      const classList = [];

      // Only adds input/select classes based on type if no component was passed.
      if (!this.component) {
        const baseClass = SELECTS.includes(this.type) ? 'custom-select' : 'form-control';
        classList.push(baseClass);

        if (this.size) {
          classList.push(`${baseClass}-${this.size}`);
        }
      }

      if (this.model.$dirty) {
        if (this.model.$pending) {
          classList.push('is-pending');
        } else if (this.model.$model || this.model.$params?.required) {
          classList.push(this.model.$invalid ? 'is-invalid' : 'is-valid');
        }
      }

      return classList.join(' ');
    },

    /**
     * Input name. Transforms label if name property is not present for accessibility.
     *
     * @returns {string}
     */
    inputName() {
      return this.name || snakeCase(this.label);
    },

    /**
     * Returns translated label if translation exists, returns plain label otherwise.
     *
     * Note: We can't destructure $t and $te because of this issue: https://github.com/kazupon/vue-i18n/issues/259.
     *
     * @returns {string}
     */
    translatedLabel() {
      const { label } = this;
      return this.$te(label) ? this.$t(label) : label;
    },
  },
};
</script>
