import { createFields } from '@/mixins/validation/createFields';
import { createModel } from '@/mixins/validation/createModel';
import { createValidations } from '@/mixins/validation/createValidations';

/**
 * @param {Object[]} form
 *
 * @returns {Object}
 */
export function createValidationMixin(form) {
  return {
    data() {
      return {
        formFields: createFields(form),
        formModel: createModel(form),
      };
    },

    validations() {
      return createValidations(form);
    },
  };
}
