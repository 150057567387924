import { get, has } from 'lodash-es/object';
import { store } from '@/store';

/**
 * @param {Object[]} form
 *
 * @returns {Object}
 */
export function createModel(form) {
  return form.reduce((acc, field) => {
    const { model, name } = field;

    if (field.hasOwnProperty('children')) {
      return {
        ...acc,
        [name]: createModel(field.children),
      };
    }

    let value;

    if (model) {
      const isGetter = model.includes('/');

      if (isGetter) {
        value = get(store.getters, model);
      } else if (has(store.state, model)) {
        value = get(store.state, model, null);
      } else {
        // eslint-disable-next-line no-console
        console.warn(`Model "${model}" not found in the Vuex store.`);
      }
    }

    if (!name) {
      return acc;
    }

    return {
      ...acc,
      [name]: value ?? field.value,
    };
  }, {});
}
