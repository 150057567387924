<template>
  <div
    v-if="fieldData.hasOwnProperty('children')"
    class="mx-n1 row">
    <FormField
      v-for="child in fieldData.children"
      :key="'form--' + name + '__' + fieldData.name + '__' + child.name"
      class="col px-1"
      :field-data="child"
      hide-errors
      :name="fieldData.name"
      v-bind="child.attributes"
      :sub="sub ? `${sub}.${fieldData.name}` : fieldData.name"
      :v="v"
      @submit="() => $emit('submit')" />

    <FieldErrors
      v-show="model.$anyError"
      :key="'form--' + name + '__' + fieldData.name + '--errors'"
      class="col-12"
      :data="fieldData"
      :model="model" />
  </div>

  <div
    v-else-if="row"
    class="row"
    v-bind="fieldData.attributes">
    <div
      class="col"
      v-bind="fieldData.columnAttributes">
      <Field
        :key="'form__' + fieldData.name"
        :hide-errors="hideErrors"
        :model="model"
        v-bind="attributes"
        @submit="() => $emit('submit')" />
    </div>
  </div>
  <Field
    v-else
    :key="'form__' + fieldData.name"
    :hide-errors="hideErrors"
    :model="model"
    v-bind="attributes"
    @submit="() => $emit('submit')" />
</template>

<script>
import { get, set } from 'lodash-es/object';
import Field from '@/components/forms/Field';
import FieldErrors from '@/components/forms/FieldErrors';

export default {
  name: 'FormField',
  components: { FieldErrors, Field },
  props: {
    /**
     * Vuelidate model.
     */
    v: {
      type: Object,
      default: null,
    },

    /**
     * Form name.
     */
    name: {
      type: String,
      default: null,
    },

    /**
     * The data of the field.
     */
    fieldData: {
      type: Object,
      default: () => ({}),
    },

    /**
     * To turn the field into a skeleton loader.
     */
    skeleton: {
      type: Boolean,
    },

    /**
     * To render a row around the field or not.
     */
    row: {
      type: Boolean,
    },

    /**
     * Used to hide the field errors.
     */
    hideErrors: {
      type: Boolean,
    },

    /**
     * Key of sub form, if any.
     */
    sub: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      /**
       * Function to remove the store watcher initiated in mounted.
       *
       * @type {Function}
       */
      unwatchStore: null,
    };
  },

  computed: {
    attributes() {
      let name = null;

      if (!this.skeleton) {
        name = this.sub
          ? `${this.sub}.${this.fieldData.name}`
          : this.fieldData.name;
      }

      return {
        component: this.fieldData.component,
        inputAttributes: this.fieldData.inputAttributes,
        label: this.fieldData.label || `form.${this.fieldData.name}`,
        name,
        size: this.fieldData.size || 'md',
        skeleton: this.skeleton,
        type: this.fieldData.type,
      };
    },

    /**
     * Search for the model. Returns empty object if skeleton to avoid errors where model is used.
     *
     * @returns {Object}
     */
    model() {
      if (this.skeleton || !this.fieldData.name) {
        return {};
      }

      const dotPath = this.getValidationModelPath();
      const model = get(this.getFormModel(), dotPath, null);

      if (!model) {
        throw new Error(`Model "${dotPath}" not found in formModel.`);
      }

      return model;
    },
  },

  mounted() {
    if (this.fieldData.model) {
      this.watchStore();
    }
  },

  beforeDestroy() {
    if (this.fieldData.model) {
      this.unwatchStore();
    }
  },

  methods: {
    /**
     * Retrieves the form model and throws an error if it's missing.
     *
     * @returns {Object}
     */
    getFormModel() {
      const formModel = this.v?.formModel;

      if (!formModel) {
        throw new Error('formModel is undefined.');
      }

      return formModel;
    },

    /**
     * Get path of the current setting in the validations model.
     *
     * @returns {String}
     */
    getValidationModelPath() {
      const path = [this.fieldData.name];

      if (this.sub) {
        path.unshift(this.sub);
      }

      return path.join('.');
    },

    /**
     * Updates form values when the corresponding value in the store changes.
     */
    watchStore() {
      const storeModel = this.fieldData.model;
      const isGetter = storeModel.includes('/');

      const getter = () => {
        return get(isGetter ? this.$store.getters : this.$store.state, storeModel);
      };

      const callback = (newValue) => {
        // Change the string value of the vuelidate form model.
        set(this.getFormModel(), `${this.getValidationModelPath()}.$model`, newValue);
      };

      this.unwatchStore = this.$store.watch(getter, callback);
    },
  },
};
</script>
