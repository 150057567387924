import * as VALIDATIONS from '@/services/validation/validations';

const FORM_ERROR_INVALID = 'form.error_invalid';

/**
 * @param {Object} model - Vuelidate model.
 *
 * @returns {Object[]}
 */
export const getValidationErrors = (model) => {
  return [
    { name: 'alpha' },
    { name: 'alphaNum' },
    {
      name: 'email',
      path: FORM_ERROR_INVALID,
    },
    { name: 'integer' },
    {
      name: 'match',
      path: FORM_ERROR_INVALID,
    },
    { name: 'numeric' },
    {
      name: 'maxLength',
      args: {
        len: model.$params?.maxLength?.max,
      },
    },
    {
      name: 'minLength',
      args: {
        len: model.$params?.minLength?.min,
      },
    },
    {
      name: 'minValue',
      args: {
        value: model.$params?.minValue?.min,
      },
    },
    {
      name: VALIDATIONS.PHONE_NUMBER,
      path: FORM_ERROR_INVALID,
    },
    {
      name: VALIDATIONS.POSTAL_CODE,
      path: FORM_ERROR_INVALID,
    },
    { name: 'required' },
  ];
};
