<template>
  <select
    v-model="mutableValue"
    v-test="{ id: 'select' }"
    class="custom-select form-select">
    <option
      v-t="disabledOption"
      disabled />
    <option
      v-for="option in options"
      :key="option.value"
      :value="option.value"
      v-text="option.text" />
  </select>
</template>

<script>
import config from '@/helpers/config';

export default {
  name: 'CountrySelect',
  props: {
    disabledOption: {
      type: String,
      default: 'form.address_country',
    },
    value: {
      type: String,
      default: config.defaultCC,
    },
  },
  data() {
    return {
      options: null,
    };
  },

  computed: {
    mutableValue: {
      get() {
        return this.value;
      },
      set(mutableValue) {
        this.$emit('input', mutableValue);
      },
    },
  },

  watch: {
    /**
     * Update the countries list on load and locale change.
     */
    '$i18n.locale': {
      async handler(locale) {
        const countries = await require(`@/translations/countries/${locale}.json`);

        this.options = Object.keys(countries).map((country) => {
          return {
            value: country,
            text: countries[country],
          };
        });
      },
      immediate: true,
    },
  },
};
</script>
