/**
 * @param {Object[]} fields
 *
 * @returns {Object}
 */
export function createFields(fields) {
  return fields.map((field) => {
    const {
      value,
      validations,
      ...rest
    } = field;
    const fieldName = field.name ? { name: field.name } : {};

    if (field.hasOwnProperty('children')) {
      return {
        ...rest,
        ...fieldName,
        children: createFields(field.children),
      };
    }

    return {
      ...rest,
      ...fieldName,
    };
  });
}
