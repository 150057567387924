import merge from 'lodash-es/merge';

export class AbstractField {
  /**
   * @type {Object}
   */
  #field = {};

  /**
   * @type {String|Object}
   */
  #attributes = null;

  /**
   * @param {String|Object} attributes - Model name or additional attributes object.
   * @returns {Object}
   */
  constructor(attributes = null) {
    this.#attributes = attributes;
    this.validate();
    return this.getMerged();
  }

  getField() {
    return this.#field;
  }

  /**
   * Merge #attributes and #field to get the final field data.
   *
   * @returns {Object}
   */
  getMerged() {
    let field = this.getField();

    if (this.#attributes) {
      if (typeof this.#attributes === 'string') {
        field = merge({}, this.getField(), { model: this.#attributes });
      } else {
        field = merge({}, this.getField(), this.#attributes);
      }
    }

    return field;
  }

  /**
   * Throws errors if something is wrong in the entered data.
   */
  validate() {
    const mergedField = this.getMerged();

    if (!mergedField.name) {
      throw new Error('Field is missing "name" property.');
    }

    if (this.#field.model) {
      throw new Error('Property "model" should be set via attributes property.');
    }
  }
}
