import MyParcelApi from '@/services/MyParcelApi';
import { createConditionalValidator } from '@/services/validation/createConditionalValidator';

/**
 * Validates postal code through our API based on value in given countryPath.
 *
 * @param {String} countryPath
 *
 * @returns {Function<Boolean>}
 */
export function createPostalCodeValidator(countryPath = 'cc') {
  return createConditionalValidator(countryPath, (value, country) => {
    return MyParcelApi.checkPostalCode({
      country,
      postalCode: value,
    });
  }, true);
}
