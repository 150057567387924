import { AbstractField } from '@/data/forms/fields/abstractField';
import config from '@/helpers/config';

export class CountryField extends AbstractField {
  getField() {
    return {
      name: 'cc',
      value: config.defaultCC,
      label: 'form.address_country',
      type: 'countrySelect',
    };
  }
}
